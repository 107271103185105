/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';
import Image from '@nubank/nuds-web/components/Image/Image';

import FormBox from '../../../../components/FormBox/FormBox';

const HERO_IMAGE_BELINDA = {
  xs: 'masterbrand-hero/hero-belinda-mobile.jpg',
  lg: 'masterbrand-hero/hero-belinda-desktop.jpg',
};

const StyledBox = styled(Box)`
  picture {
    width: 100%;

    img {
      border-radius: 0 0 20px 20px;
    }
  }  
`;

const BelindaTitleTypography = styled(Typography)`
  font-weight: 500;
  font-size: 32px;
  line-height: 35.84px;
  letter-spacing: -3%;

  @media (width >= 768px) {
    font-size: 92px;
    line-height: 95.68px;
  }
`;

const HeroSection = () => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  return (
    <>
      <Box
        marginBottom={{ xs: '0', lg: '24x' }}
        maxWidth="100vw"
        tag="section"
        width="100%"
      >
        <StyledBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
        >
          <Image
            srcSet={HERO_IMAGE_BELINDA}
            alt="Imagen de dos tarjetas moradas flotando con un fondo violeta"
            height={{ xs: `${screenHeight}px`, lg: 'auto' }}
            width="100%"
            objectFit="cover"
            objectPosition="top"
          />

          <Box
            position="absolute"
            display="flex"
            flexDirection={{ xs: 'column' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            justifyContent={{ xs: 'space-between', lg: 'center' }}
            height={{ xs: `${screenHeight - (screenHeight / 25)}px`, lg: '100%' }}
            paddingHorizontal={{ xs: '4x', md: '12x', lg: '16x' }}
            width="95vw"
          >

            <Box
              display="flex"
              flexDirection="column"
              marginTop={{ xs: '16x', lg: '0' }}
              marginBottom={{ xs: '0', md: '6x', lg: '8x' }}
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <BelindaTitleTypography
                variant="heading1"
                tag="h1"
                color="white"
                textAlign={{ xs: 'center', lg: 'left' }}
                whiteSpace={{ xs: 'inherit', lg: 'pre-line' }}
                maxWidth={{ xs: '14ch', lg: '100%' }}
              >
                {' 10 millones ya  \n tomaron la \n decisión Nu'}
              </BelindaTitleTypography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
            >

              <Typography
                variant="subtitle1"
                whiteSpace={{ xs: 'inherit', lg: 'pre-line' }}
                tag="p"
                textAlign={{ xs: 'center', lg: 'left' }}
                color="white"
                strong
                maxWidth={{ xs: '342px', md: '380px', lg: '470px' }}
              >
                Tener Tarjeta de Crédito sin anualidad y Cuenta Nu
                de Débito para que tu dinero crezca, es una buena decisión
              </Typography>

              <FormBox
                buttonLabel="HOME.MULTIPRODUCT.HERO.CTA.BTN"
                inputAndButtonWidth={{ xs: '335px' }}
                buttonBackgroundColor="#820AD1"
                buttonGradientColor="linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%),linear-gradient(0deg, #2DB965, #2DB965)"
                buttonLabelColor="#FFFFFF"
                inputTransparentBackground
                inputLabelColor="#FFFFFF"
                ctaHide
                newDesign
              />
            </Box>
          </Box>

        </StyledBox>
      </Box>
    </>
  );
};

export default HeroSection;
